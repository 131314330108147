/**
 * File library.js
 * 
 * Custom scripts.
 */

// Variables

const content = document.getElementById( 'content' );
const header = document.getElementById( 'masthead' );
const headerHeight = header.offsetHeight;
const footer = document.getElementById( 'colophon' );

const menuOffCanvas = document.getElementById( 'menu-offcanvas' );
const menu = menuOffCanvas.getElementsByTagName( 'ul' )[0];
const menuButton = document.getElementById( 'menu-button' );
const openMenu = document.getElementsByClassName( 'open-menu' );
const closeMenu = document.getElementsByClassName( 'close-menu' );

if ( document.body.classList.contains( 'is-desktop' ) ) {
	// Adjust top margin for fixed header
	content.style.marginTop = headerHeight + "px";
} else {
	// Adjust bottom margin for fixed toolset
	var toolset = document.getElementById( 'toolset' );
	var toolsetHeight = toolset.offsetHeight;
	footer.style.marginBottom = toolsetHeight + "px";
}

// All-click objects
const clickboxes = document.querySelectorAll( '.allclick' );

clickboxes.forEach( function(item) {
	item.addEventListener( 'click', function(event) {
		item.querySelector( 'a' ).click();
	} );
} );

// Inhibit hashtag links
const links = document.querySelectorAll( 'a[href="#"]' );

for ( var i = 0; i < links.length; i++ ) {
	links[i].addEventListener( 'click', function(event) {
		event.preventDefault();
	} );
}

// Manage navigation menu with respect to the device
if ( menu.querySelectorAll('.menu-item-has-children').length > 0 ) {
	const menuItems = menu.getElementsByClassName( 'menu-item-has-children' );

	if ( document.body.classList.contains( 'is-mobile' ) ) {
		const allSubmenu = menu.getElementsByClassName( 'sub-menu' );

		for ( var i = 0; i < menuItems.length; i++ ) {
			let submenu = menuItems[i].getElementsByTagName( 'ul' )[0];

			menuItems[i].addEventListener( 'click', function() {
				for ( var j = 0; j < allSubmenu.length; j++ ) {
					allSubmenu[j].classList.remove( 'open' );
				}

				submenu.classList.toggle( 'open' );
			} );
		}
	} else {
		for ( var i = 0; i < menuItems.length; i++ ) {
			let submenu = menuItems[i].getElementsByTagName( 'ul' )[0];

			menuItems[i].addEventListener( 'mouseover', function() {
				submenu.classList.add( 'open' );
			} );

			menuItems[i].addEventListener( 'mouseout', function() {
				submenu.classList.remove( 'open' );
			} );
		}
	}
}

// Main slider
if ( document.getElementsByClassName( 'slideshow' ).length > 0 ) {
	var nslider = tns({
		container: '.slideshow',
		mode: 'gallery',
		mouseDrag: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		autoplayButtonOutput: false,
		speed: 1000,
		items: 1,
		controls: false,
		nav: false
	});
}

// Rooms slider
if ( document.getElementsByClassName( 'rooms-slideshow' ).length > 0 ) {
	var nslider = tns({
		container: '.rooms-slideshow',
		mouseDrag: true,
		speed: 500,
		items: 1,
		controlsContainer: '#slider-controls',
		nav: false,
		responsive: {
			992: {
				items: 2
			}
		}
	});
}

// Offers slider
if ( document.getElementsByClassName( 'offers-slideshow' ).length > 0 ) {
	var nslider = tns({
		container: '.offers-slideshow',
		mouseDrag: true,
		speed: 500,
		items: 1,
		controls: false,
		nav: false,
		responsive: {
			1200: {
				items: 2,
				edgePadding: 100
			}
		}
	});
}

// Reasons slider
var reasons_slider = document.querySelector( '.reasons-slideshow' );
if ( reasons_slider !== null ) {
	var items = reasons_slider.getAttribute( 'data-tns-items' );
	var nslider = tns({
		container: '.reasons-slideshow',
		mouseDrag: true,
		speed: 500,
		items: 1,
		controls: false,
		nav: false,
		responsive: {
			768: {
				items: 2,
				edgePadding: 100
			},
			1200: {
				items: items
			}
		}
	});
}

// Gallery slider
if ( document.getElementsByClassName( 'gallery-slideshow' ).length > 0 ) {
	var nslider = tns({
		container: '.gallery-slideshow',
		mouseDrag: true,
		speed: 500,
		items: 1,
		edgePadding: 0,
		controls: false,
		nav: false,
		responsive: {
			768: {
				items: 2				
			},
			992: {
				edgePadding: 50
			},
			1600: {
				edgePadding: 100
			}
		}
	});
}

// Reviews slider
if ( document.getElementsByClassName( 'reviews-slideshow' ).length > 0 ) {
	var nslider = tns({
		container: '.reviews-slideshow',
		mouseDrag: true,
		speed: 500,
		items: 1,
		edgePadding: 50,
		controls: false,
		nav: false,
		responsive: {
			768: {
				items: 2,
				edgePadding: 100
			}
		}
	});
}

// Scroll behaviours
var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
	var currentScrollPos = window.pageYOffset;

	if ( document.body.classList.contains( 'is-desktop' ) ) {
		// Toggle class for header on scroll
		if ( currentScrollPos > prevScrollpos ) {
			header.classList.add( 'moved' );
		}

		if ( currentScrollPos == 0 ) {
			header.classList.remove( 'moved' );
		}

		prevScrollpos = currentScrollPos;
	}

	menuButton.style.transform = 'rotate(' + (window.pageYOffset / 4) + 'deg)';
}

// Magic mouse
if ( document.body.classList.contains( 'is-desktop' ) ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 24,
		"outerHeight": 24
	};

	magicMouse(options);
}

// GSAP menu animation
const logo = document.querySelector( '.logo' );
const menuItems = menu.querySelectorAll( 'li' );
const languages = document.querySelector( 'ul.languages' );
const circleBefore = menuOffCanvas.querySelector( '.circle-before' );
const circleAfter = menuOffCanvas.querySelector( '.circle-after' );

var timeline = gsap.timeline({
	paused: true
});

timeline.to(
	menuOffCanvas,
	{
		duration: 0.6,
		scaleY: 1,
		autoAlpha: 1,
		ease: 'power2.inOut'
	}
).to(
	languages,
	{
		duration: 0.3,
		autoAlpha: 1,
		ease: 'power2.inOut',
		onReverseComplete: () => gsap.set(
			languages, { clearProps: true }
		)
	}
).to(
	logo,
	{
		duration: 0.5,
		left: 0,
		ease: 'back.out(1.2)',
		onReverseComplete: () => gsap.set(
			logo, { clearProps: true }
		)
	}
).from(
	menuItems,
	{
		duration: 1,
		autoAlpha: 0,
		y: 20,
		stagger: 0.1,
		ease: 'power2.inOut'
	}
).from(
	circleBefore,
	{
		duration: 0.5,
		autoAlpha: 0,
		y: 20,
		ease: 'power2.inOut'
	}
).from(
	circleAfter,
	{
		duration: 0.5,
		autoAlpha: 0,
		y: 20,
		ease: 'power2.inOut'
	}
);

// Toggle class for off canvas menu on click
menuButton.addEventListener( 'click', function() {
	document.body.classList.toggle( 'open-menu' );

	if ( document.body.classList.contains( 'open-menu' ) ) {
		timeline.timeScale(1).play();
	} else {
		timeline.timeScale(3).reverse();
	}
} );
